<template>
  <v-card v-if="candidate" min-height="250px">
    <v-row class="d-flex flex-column align-center py-10">
      <div class="mb-4">
        <v-avatar left>
          <v-img
            :src="
              candidate.interviewee && candidate.interviewee.photo
                ? `${apiUrl}/images/candidate/${candidate.interviewee.photo}`
                : `/avatar.png`
            "
          ></v-img>
        </v-avatar>
      </div>
      <div class="mb-2">{{ fullname }}</div>
      <div class="font-weight-bold subtitle-1 mb-2">{{ profession }}</div>
      <div v-if="professionalExperience" class="caption">
        {{ days(professionalExperience) }} {{ $t("years_of_experience") }}
      </div>
      <div v-else class="caption">Sem experiência</div>
    </v-row>
  </v-card>
</template>

<script>
import { API_URL } from "@/api";
import yearsMixins from "@/mixins/years";
export default {
  name: "ApprovedCandidateCard",

  props: {
    candidate: { type: Object },
  },

  mixins: [yearsMixins],

  data: () => ({ apiUrl: API_URL }),

  computed: {
    fullname() {
      return this.candidate.interviewee
        ? this.candidate.interviewee.fullName
        : this.candidate.email;
    },

    profession() {
      return this.candidate.interviewee
        ? this.candidate.interviewee.profession
        : "";
    },

    professionalExperience() {
      return this.candidate.interviewee
        ? this.candidate.interviewee.professionalExperience
        : null;
    },
  },
};
</script>