var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"px-5 py-5"},[_c('v-row',{staticClass:"d-flex flex-row justify-space-between align-center caption"},[_c('div',{staticClass:"d-flex flex-row align-center whirelab-card-preview-candidates"},[_c('div',{staticClass:"mr-4"},[(_vm.isGroup)?_c('div',{staticClass:"avatars"},_vm._l((_vm.candidates),function(candidate){return _c('div',{key:candidate.interviewee
                ? candidate.interviewee.id
                : candidate.email,staticClass:"avatar"},[_c('img',{attrs:{"src":candidate.interviewee && candidate.interviewee.photo
                  ? `${_vm.apiUrl}/images/candidate/${candidate.interviewee.photo}`
                  : `/avatar.png`}})])}),0):_c('v-avatar',{attrs:{"size":"30"}},[_c('img',{attrs:{"src":_vm.candidate.interviewee && _vm.candidate.interviewee.photo
                ? `${_vm.apiUrl}/images/candidate/${_vm.candidate.interviewee.photo}`
                : `/avatar.png`}})])],1),_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.isGroup ? _vm.$t("group_interview") : _vm.candidate.interviewee ? _vm.candidate.interviewee.fullName : _vm.candidate.email)+" ")])])]),_c('div',{staticClass:"d-flex flex-row justify-center align-center"},[_c('div',[_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.localDate))])])]),_c('div',{staticClass:"d-flex flex-row justify-end align-center text-right"},[_c('div',{staticClass:"text-right"},[_c('v-alert',{staticClass:"py-0 mb-0 caption text-uppercase text-center",attrs:{"dense":"","outlined":"","color":`${_vm.status.color}`,"width":"100px"}},[_vm._v(_vm._s(_vm.$t(_vm.status.text)))])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }