<template>
  <v-card class="px-5 py-5">
    <v-row class="d-flex flex-row justify-space-between align-center caption">
      <div
        class="d-flex flex-row align-center whirelab-card-preview-candidates"
      >
        <div class="mr-4">
          <div class="avatars" v-if="isGroup">
            <div
              class="avatar"
              v-for="candidate in candidates"
              :key="
                candidate.interviewee
                  ? candidate.interviewee.id
                  : candidate.email
              "
            >
              <img
                :src="
                  candidate.interviewee && candidate.interviewee.photo
                    ? `${apiUrl}/images/candidate/${candidate.interviewee.photo}`
                    : `/avatar.png`
                "
              />
            </div>
          </div>
          <v-avatar v-else size="30">
            <img
              :src="
                candidate.interviewee && candidate.interviewee.photo
                  ? `${apiUrl}/images/candidate/${candidate.interviewee.photo}`
                  : `/avatar.png`
              "
            />
          </v-avatar>
        </div>
        <div>
          <div>
            {{
              isGroup
                ? $t("group_interview")
                : candidate.interviewee
                ? candidate.interviewee.fullName
                : candidate.email
            }}
          </div>
        </div>
      </div>
      <div class="d-flex flex-row justify-center align-center">
        <div>
          <span class="text--secondary">{{ localDate }}</span>
        </div>
      </div>
      <div class="d-flex flex-row justify-end align-center text-right">
        <div class="text-right">
          <v-alert
            dense
            outlined
            :color="`${status.color}`"
            class="py-0 mb-0 caption text-uppercase text-center"
            width="100px"
            >{{ $t(status.text) }}</v-alert
          >
        </div>
      </div>
    </v-row>
  </v-card>
</template>

<script>
import { API_URL } from "@/api";
import format from "date-fns/format";
import { pt, enUS } from "date-fns/locale";
export default {
  name: "PanelCardPreview",

  props: {
    interview: { type: Array },
  },

  data: () => ({
    apiUrl: API_URL,
  }),

  computed: {
    isGroup() {
      return this.interview.length > 1;
    },
    candidate() {
      const FIRST_ELEMENT = 0;
      return this.interview[FIRST_ELEMENT];
    },
    candidates() {
      return this.interview;
    },
    localDate() {
      const currentLang = this.$i18n.locale;

      const stage = this.candidate.stages.find(
        (findCandidate) => findCandidate.name === this.candidate.currentStage
      );
      let formattedDate;
      switch (currentLang) {
        case "en":
          formattedDate = format(
            new Date(`${stage.date} ${stage.time}`),
            "yyyy/MM/dd hh:mm a",
            { locale: enUS }
          );
          break;

        default:
          formattedDate = format(
            new Date(`${stage.date} ${stage.time}`),
            "dd/MM/yyyy HH:mm",
            { locale: pt }
          );
          break;
      }

      return formattedDate;
    },
    status() {
      let states = [];
      const PENDING = "pending";
      const CANDIDATES = this.interview;
      CANDIDATES.forEach((mapCandidate) =>
        mapCandidate.stages.forEach((mapStage) => {
          if (mapCandidate.status === PENDING) {
            states.push(mapStage.state);
          } else {
            states.push(mapCandidate.status);
          }
        })
      );

      const STATUS = states;
      const TOTAL_STATUS = STATUS.length;
      let statusIndex = 0;
      let pending = 0,
        running = 0,
        canceled = 0,
        ongoing = 0,
        finished = 0,
        rejected = 0,
        approved = 0;
      const PENDING_STATUS = { text: this.$t("pending"), color: "cyan" };
      const ONGOING_STATUS = { text: this.$t("ongoing"), color: "warning" };
      const CANCELED_STATUS = { text: this.$t("canceled"), color: "error" };
      const REJECTED_STATUS = { text: this.$t("rejected"), color: "error" };
      const FINISHED_STATUS = { text: this.$t("finished"), color: "success" };
      const APPROVED_STATUS = { text: this.$t("approved"), color: "success" };

      for (statusIndex; statusIndex < TOTAL_STATUS; statusIndex++) {
        switch (STATUS[statusIndex]) {
          case "pending":
            pending += 1;
            break;

          case "finished":
            finished += 1;
            break;

          case "approved":
            approved += 1;
            break;

          case "rejected":
            rejected += 1;
            break;

          case "canceled":
            canceled += 1;
            break;

          case "ongoing":
            ongoing += 1;
            break;

          default:
            running += 1;
            break;
        }
      }

      if (TOTAL_STATUS === pending) {
        return PENDING_STATUS;
      } else if (TOTAL_STATUS === ongoing || ongoing >= 1 || running >= 1) {
        return ONGOING_STATUS;
      } else if (TOTAL_STATUS === canceled) {
        return CANCELED_STATUS;
      } else if (TOTAL_STATUS === approved) {
        return APPROVED_STATUS;
      } else if (TOTAL_STATUS === rejected) {
        return REJECTED_STATUS;
      } else if (
        TOTAL_STATUS === finished ||
        finished + canceled + approved + rejected === TOTAL_STATUS
      ) {
        return FINISHED_STATUS;
      }

      return PENDING_STATUS;
    },
  },
};
</script>

<style scoped>
.avatars {
  padding-right: 20px;
}
.avatar {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  width: 40px;
}
.avatar:not(:first-child) {
  margin-left: -30px;
  -webkit-mask: radial-gradient(
    circ30 25px at 5px 50%,
    transparent 99%,
    #fff 100%
  );
  mask: radial-gradient(circle 25px at 5px 50%, transparent 99%, #fff 100%);
}
.avatar img {
  width: 100%;
  display: block;
}

@media only screen and (min-width: 600px) {
}
/* .whirelab-card-preview-candidates {

} */
</style>