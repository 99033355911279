<template>
  <div class="card">
    <div class="skeleton avatar mb-5"></div>
    <div class="skeleton skeleton-text mb-5"></div>
    <div class="skeleton skeleton-profession mb-5"></div>
    <div class="skeleton skeleton-text mb-5"></div>
  </div>
</template>

<script>
export default {
  name: "ApprovedCandidateCardLoader",
};
</script>

<style scoped>
.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-text {
  /* width: 100%; */
  width: 50%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

.skeleton-profession {
  /* width: 100%; */
  width: 60%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

.skeleton-text:last-child {
  margin-bottom: 0;
  width: 25%;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  padding: 50px 12px;
  border-radius: 4px;
}

.avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
</style>