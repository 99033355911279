<template>
  <div class="card">
    <div class="candidate">
      <div class="skeleton avatar"></div>
      <div class="skeleton skeleton-text"></div>
    </div>

    <div class="date"><div class="skeleton skeleton-text"></div></div>

    <div class="status"><div class="skeleton status-chip"></div></div>
  </div>
</template>

<script>
export default {
  name: "PanelCardPreviewLoader",
};
</script>

<style scoped>
.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-text {
  /* width: 100%; */
  width: 50%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

.skeleton-text:last-child {
  margin-bottom: 0;
  width: 50%;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.card {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  padding: 16px 10px;
  border-radius: 4px;
}

.candidate {
  display: flex;
  align-items: center;
  width: calc(100% / 3);
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.date {
  display: flex;
  justify-content: center;
  width: calc(100% / 3);
}

.status {
  display: flex;
  justify-content: flex-end;
  width: calc(100% / 3);
}

.status-chip {
  width: 100px;
  height: 20px;
  border-radius: 5px;
}
</style>