var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fill-heigt",attrs:{"fluid":""}},[_c('v-row',{staticClass:"justify-center",staticStyle:{"margin-top":"-45px"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-alert',{staticClass:"text-center caption mb-0",attrs:{"dense":"","type":"info","dismissible":""},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(_vm._s(_vm.$t("alert_interview")))])],1)],1),_c('v-row',[_c('div',[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.goBack()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)],1)]),_c('v-row',{staticClass:"mt-12"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{},[_c('v-card-title',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t("scheduled_interviews"))+" "),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"5px"},attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.openScheduleInterviewDialog()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-calendar")]),_vm._v(_vm._s(_vm.$t("schedule_interview"))+" ")],1)]}}])},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('tooltip_interview'))}})])],1),_c('v-card',{staticClass:"whirelab-pink pt-5",staticStyle:{"min-height":"505px"},attrs:{"to":{
            name: 'scheduled.interviews',
            params: { id: _vm.interview.id },
          }}},[(_vm.loadingData)?_vm._l((5),function(i){return _c('panel-card-preview-loader',{key:i})}):[_c('v-card',{staticClass:"whirelab-pink px-5",attrs:{"elevation":"0"}},[_vm._l((_vm.interviews),function(interview){return [_c('panel-card-preview',{key:interview.timestemp,staticClass:"mb-1",attrs:{"interview":interview}})]})],2)]],2)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"to":{
          name: 'approved.interviews',
          params: { id: _vm.interview.id },
        }}},[_c('v-card-title',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t("approved_candidates")))]),_c('v-card-text',{staticClass:"whirelab-pink pa-10 pt-12"},[(_vm.loadingData)?_c('v-row',{staticStyle:{"min-height":"500px"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('approved-candidate-card-loader')],1)],1):_vm._e(),(_vm.approvedCandidates.length > 0)?_c('v-row',{staticStyle:{"min-height":"500px"}},_vm._l((_vm.approvedCandidates),function(candidate){return _c('v-col',{key:candidate.id,attrs:{"cols":"12","md":"6"}},[_c('approved-candidate-card',{attrs:{"candidate":candidate}})],1)}),1):_c('v-row',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticClass:"default-plus"},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-plus")])],1)])],1)],1)],1)],1),_c('add-candidate-to-interview',{on:{"added":_vm.addCandidate}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }